import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, mergeMap, of } from 'rxjs';
import { User } from 'src/app/model/user';
import { FixedWorkstation, FixedWorkstationInterface, WorkstationCession, WorkstationCessionInterface } from '../../model/fixed-workstation';
import { FixedWorkstationCessionEdition } from '../../pages/fixed-resources/workstation/cession/cession.component';
import { ApiCustomError } from './common/api-error';
import { ApiPathBuilder, BaseApiService } from './common/base-api.service';
import { UserApiService } from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export class FixedWorkstationApiService extends BaseApiService {
  public static readonly ALREADY_CEDED_ERROR = 4057;
  public static readonly WORKSTATION_ALREADY_BOOKING = 4060;

  private fixedResourceCache = new Map<string, Observable<FixedWorkstation | undefined>>();
  private promiseCessions: Promise<WorkstationCession[]> | null = null;

  public constructor(
    http: HttpClient,
    private userApi: UserApiService,
  ) {
    super(http);
  }

  public override clear(): void {
    this.fixedResourceCache = new Map<string, Observable<FixedWorkstation>>();
    this.promiseCessions = null;
  }

  public getFixedWorkstation(user?: User): Observable<FixedWorkstation | undefined> {
    const id = user?.id || 'me';
    let fixedResourceCache = this.fixedResourceCache.get(id);
    if (fixedResourceCache == null) {
      fixedResourceCache = this.caching(
        this.get<FixedWorkstationInterface>(ApiPathBuilder.v2('users', id, 'resources', 'workstations', 'fixedworkstation')).pipe(
          mergeMap(fixedWorkstation => {
            const floorId = fixedWorkstation.workstation.floor?.id;
            if (floorId == null) {
              throw new Error('Undefined floor for this fixed workstation');
            }
            return forkJoin([ this.userApi.getResources().pipe(map(resources => resources.findBuildingFromFloor(floorId))), of(fixedWorkstation) ]);
          }),
          map(([ building, fixedWorkstation ]) => new FixedWorkstation(fixedWorkstation, building ?? undefined)),
          catchError(error => {
            if (error instanceof ApiCustomError && error.statusCode === HttpStatusCode.NotFound) {
              return of(undefined);
            } else {
              throw error;
            }
          }),
        ),
      );

      this.fixedResourceCache.set(id, fixedResourceCache);
    }
    return fixedResourceCache;
  }

  public getCessions(): Promise<WorkstationCession[]> {
    if (this.promiseCessions === null) {
      this.promiseCessions = this._get<CessionsResponse>(ApiPathBuilder.v1('users', 'me', 'workstation', 'cessions')).then(response =>
        response.cessions.map(cession => WorkstationCession.create(cession)),
      );
    }
    return this.promiseCessions;
  }

  public addCession(cession: FixedWorkstationCessionEdition): Promise<readonly WorkstationCession[]> {
    return this._post<CessionsResponse>(ApiPathBuilder.v1('users', 'me', 'workstation', 'cessions'), cession).then(response => {
      this.promiseCessions = null;
      return response.cessions.map(newCession => WorkstationCession.create(newCession));
    });
  }

  public deleteCession(cession: WorkstationCession): Promise<void> {
    return this._delete<void>(ApiPathBuilder.v1('users', 'me', 'workstation', 'cessions', cession)).then(() => {
      this.promiseCessions = null;
    });
  }

  public deleteRecurrentCessions(cession: WorkstationCession): Promise<void> {
    if (cession.recurrentId == null) {
      throw new Error('Non-recurrent cession');
    }
    return this._delete<void>(ApiPathBuilder.v1('users', 'me', 'workstation', 'cessions', 'recurrent', cession.recurrentId)).then(() => {
      this.promiseCessions = null;
    });
  }

  public deleteAllCessions(): Promise<void> {
    return this._delete<void>(ApiPathBuilder.v1('users', 'me', 'workstation', 'cessions', 'deleteAll')).then(() => {
      this.promiseCessions = null;
    });
  }

  public checkIn(fixedWorkstation: FixedWorkstation): Promise<void> {
    return this._post<void>(ApiPathBuilder.v2('users', 'me', 'fixedresources', 'checkin'), { resourceId: fixedWorkstation.getResource().id }).then(
      () => {
        fixedWorkstation.checkInStatus = 'CHECK_IN_DONE';
      },
    );
  }

  public checkOut(fixedWorkstation: FixedWorkstation): Promise<void> {
    return this._post<void>(ApiPathBuilder.v2('users', 'me', 'fixedresources', 'checkout'), { resourceId: fixedWorkstation.getResource().id }).then(
      () => {
        fixedWorkstation.checkInStatus = 'CHECK_OUT_DONE';
      },
    );
  }
}

interface CessionsResponse {
  cessions: WorkstationCessionInterface[];
}
